<!-- Server status with no description-->
<div *ngIf="!warning.text">
	<div *ngIf="warning.style === CriticalWarningStyle.WARNING || warning.style === CriticalWarningStyle.DANGER" class="warning-container no-message" [class]=warning.style>
		<div class="warning-icon" [ngStyle]="{'background-image': 'url(' + getWarningIcon(warning.style) + ')'}"></div>
		<div *ngIf="warning.style === CriticalWarningStyle.WARNING" class="warning-title">
			We’re currently experiencing server issues
		</div>
		<div *ngIf="warning.style === CriticalWarningStyle.DANGER" class="warning-title">
			Everything’s on fire!
		</div>
	</div>
</div>

<!-- Warning text-->
<div *ngIf="warning.text">
	<div *ngIf="warning.style === CriticalWarningStyle.WARNING || warning.style === CriticalWarningStyle.DANGER" class="warning-container" [class]=warning.style>
		<div class="big-warning-title-container">
			<div class="warning-icon" [ngStyle]="{'background-image': 'url(' + getWarningIcon(warning.style) + ')'}"></div>
        <div class="message-container">
          <p class="warning-title" [class]="!warning.destinationUrl ? 'with-no-link' : ''">
            {{ warning.text }}
          </p>
          <a *ngIf="warning.destinationUrl && !warning.isInternalUrl" class="message-link" target="_blank" [href]="warning.destinationUrl">{{ warning.urlText }}</a>
        </div>

			<div class="time" [class]="warning.style"> {{ time }} </div>
		</div>
	</div>
</div>
