import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { FollowRequestsResponse } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class FollowService {

	private readonly followRequestsSource = new Subject<FollowRequestsResponse>();
	public readonly followRequests$ = this.followRequestsSource.asObservable();

	public constructor(
		protected readonly http: HttpClient,
	) {
		//
	}

	public reloadUserFollowRequests(): void {
		this.http.get<FollowRequestsResponse>(`${environment.homeApiUrl}/api/follow/v1/follow-requests`, { headers: AuthService.getHeaders() }).subscribe(
			(followRequests: FollowRequestsResponse) => {
				this.followRequestsSource.next(followRequests);
				return followRequests;
			},
			(e) => {
				console.log(e);
				return null;
			},
		);
	}

	public async sendRequest(userId: string) {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/follow/v1/${userId}/send-request`, {}, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
		}
	}

	public async unfollowOrCancelRequest(userId: string) {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/follow/v1/${userId}/unfollow`, {}, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
		}
	}

	public async acceptRequest(userId: string) {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/follow/v1/${userId}/accept`, {}, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
		}
	}

	public async ignoreRequest(userId: string) {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/follow/v1/${userId}/reject`, {}, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
		}
	}
}
