import { Component, Input } from '@angular/core';
import { CriticalWarning } from 'leetify-shared-utils/dto';
import { CriticalWarningStyle } from 'leetify-shared-utils/enums';

@Component({
	selector: 'leetify-common-critical-warning',
	templateUrl: './critical-warning.component.html',
	styleUrls: ['./critical-warning.component.scss'],
})
export class CommonCriticalWarningComponent {
	@Input() public warning: CriticalWarning;

	public CriticalWarningStyle = CriticalWarningStyle;
	protected time: string;

	protected getWarningIcon(serverStatus: string) {
		if (serverStatus === CriticalWarningStyle.WARNING) return 'assets/icons/warning-sign.svg';
		if (serverStatus === CriticalWarningStyle.DANGER) return 'assets/icons/error-sign.svg';
		return '';
	}
}
