<aside>
	<div class="mobile-nav-toggle">
		<button aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event)">
			<i class="mdi {{ navigationActive ? 'mdi-close' : 'mdi-menu' }}"></i>
		</button>

		<a routerLink='/'>
			<img class="desktop" src="assets/images/leetify-logo-primary-white.svg" alt="Leetify">
			<img class="mobile" src="assets/leetify/leetify-icon.svg" alt="Leetify">
		</a>
	</div>

	<leetify-common-player-search
		[focusSearchInput$]="focusSearchInput$"
		[ngClass]="{ '--active': searchActive }"
		(close)="toggleSearch(null, false)"
	></leetify-common-player-search>

	@if (user) {
		<div class="right">
			<button class="mobile-search-toggle" aria-label="Toggle Search" title="Toggle Search" (click)="toggleSearch($event)">
				<i class="mdi {{ searchActive ? 'mdi-close' : 'mdi-magnify' }}"></i>
			</button>

			<div class="pro">
				<a
					(click)="goToPro()"
					class="btn"
					[ngClass]="proButtonClass"
					container="body"
					[ngbTooltip]="user.isCollector ? 'Thanks for being a Founder!' : null"
					openDelay="600"
					placement="bottom"
					tooltipClass="default-tooltip"
				>
					{{ proButtonText }}
				</a>
			</div>

		<leetify-common-notifications-component
			[criticalWarnings]="criticalWarnings"
			[followRequests]="followRequests"
			[notifications]="notifications"
			[hasUnreadNotifications]="hasUnreadNotifications"
			[numberOfUnreadNotifications]="numberOfUnreadNotifications"
		></leetify-common-notifications-component>
		<leetify-common-user-menu></leetify-common-user-menu>

			<a (click)="goToProfile()" class="user-account-settings-link">
				<img [src]="profilePictureUrl || 'assets/images/anon.png'" alt="" class="rounded-circle" (error)="onProfilePictureErrored($event)">
			</a>
		</div>
	} @else {
		<div class="right --unauthenticated">
			<div class="spacer"></div>

			<div class="auth-buttons">
			<a (click)="onLogin()" class="login-button cursor-pointer">Log In</a>
			<a (click)="onSignup()" class="signup-button">Sign Up</a>
			</div>
		</div>
	}
</aside>
