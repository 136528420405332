import { Component, Input } from '@angular/core';

export enum Icon {
	/* eslint-disable @typescript-eslint/no-shadow */
	ACCOUNT = 'account',
	AIM = 'aim',
	ALERT = 'alert',
	ALERT_CIRCLE_OUTLINE = 'alert_circle_outline',
	ALLSTAR = 'allstar',
	APPLE = 'apple',
	ARROW_BOTTOM_LEFT = 'arrow_bottom_left',
	ARROW_BOTTOM_RIGHT = 'arrow_bottom_right',
	ARROW_DOWN = 'arrow_down',
	ARROW_LEFT = 'arrow_left',
	ARROW_RIGHT = 'arrow_right',
	ARROW_TOP_LEFT = 'arrow_top_left',
	ARROW_TOP_RIGHT = 'arrow_top_right',
	ARROW_UP = 'arrow_up',
	ARTSTATION = 'artstation',
	BELL = 'bell',
	BELL_FILLED = 'bell_filled',
	CHECK = 'check',
	CHECK_CIRCLE = 'check_circle',
	CHECK_DECAGRAM = 'check_decagram',
	CHEVRON_DOWN = 'chevron_down',
	CHEVRON_LEFT = 'chevron_left',
	CHEVRON_RIGHT = 'chevron_right',
	CHEVRON_UP = 'chevron_up',
	CIRCLE_ADD_PLUS = 'circle_add_plus',
	CIRCLE_ADD_PLUS_FILLED = 'circle_add_plus_filled',
	CIRCLE_CHECK_TICK = 'circle_check_tick',
	CIRCLE_CHECK_TICK_FILLED = 'circle_check_tick_filled',
	CIRCLE_CROSS_CLOSE = 'circle_cross_close',
	CIRCLE_CROSS_CLOSE_FILLED = 'circle_cross_close_filled',
	CIRCLE_INFO_FILLED = 'circle_info_filled',
	CIRCLE_MEDIUM = 'circle_medium',
	CIRCLE_QUESTION_FILLED = 'circle_question_filled',
	CIRCLE_REMOVE_MINUS = 'circle_remove_minus',
	CIRCLE_REMOVE_MINUS_FILLED = 'circle_remove_minus_filled',
	CIRCLE_SMALL = 'circle_small',
	CIRCLE_WARNING_FILLED = 'circle_warning_filled',
	CLOSE = 'close',
	CLUB = 'club',
	CLUB_INVITE = 'club_invite',
	CLUB_MEMBERS = 'club_members',
	CLUB_SUGGESTION = 'club_suggestion',
	COG = 'cog',
	COMPARE_AIM_RATING = 'compare_aim_rating',
	COMPARE_CLUTCH_RATING = 'compare_clutch_rating',
	COMPARE_OPENING_RATING = 'compare_opening_rating',
	COMPARE_POSITIONING_RATING = 'compare_positioning_rating',
	COMPARE_UTILITY_RATING = 'compare_utility_rating',
	CONTENT_COPY = 'content_copy',
	COUNTER_TERRORIST = 'counter_terrorist',
	CREDIT_CARD_BILLING = 'credit_card_billing',
	CROWN = 'crown',
	CS2_LOGO = 'cs2_logo',
	DISCORD = 'discord',
	DOTS_HORIZONTAL = 'dots_horizontal',
	EMAIL = 'email',
	EMOTICON_HAPPY_OUTLINE = 'emoticon_happy_outline',
	ESEA = 'esea',
	ESLGAMING = 'eslgaming',
	ESPORTAL = 'esportal',
	EYE_CLOSED = 'eye_closed',
	EYE = 'eye',
	EYE_LOCK = 'eye_lock',
	EYE_OFF = 'eye_off',
	EYE_OPEN = 'eye_open',
	EYE_OPEN_FILLED = 'eye_open_filled',
	FACEBOOK = 'facebook',
	FACEIT = 'faceit',
	FIRE = 'fire',
	FLASH_ASSIST = 'flash_assist',
	GAMERS_CLUB = 'gamers_club',
	GITHUB = 'github',
	HEAD_TO_HEAD_WEAPONS_BREAKDOWN = 'head_to_head_weapons_breakdown',
	HEART = 'heart',
	HELP_CIRCLE = 'help_circle',
	HIGHLIGHT_OF_THE_SESSION = 'highlight_of_the_session',
	HOME = 'home',
	IM_STILL_GONNA_CALL_IT_TWITTER = 'im_still_gonna_call_it_twitter',
	INFORMATION = 'information',
	INFORMATION_OUTLINE = 'information_outline',
	INSTAGRAM = 'instagram',
	KICK = 'kick',
	KILL_FEED_HEADSHOT = 'kill_feed_headshot',
	LEETIFY = 'leetify',
	LINKEDIN = 'linkedin',
	LOCK_FILLED = 'lock_filled',
	LOCK = 'lock',
	LOL_LOGO = 'lol-logo',
	MAP_MARKER = 'map_marker',
	MASTODON = 'mastodon',
	MENU_DOWN = 'menu_down',
	MENU_LEFT = 'menu_left',
	MENU = 'menu',
	MENU_RIGHT = 'menu_right',
	MENU_SWAP = 'menu_swap',
	MENU_UP = 'menu_up',
	MICROPHONE = 'microphone',
	MINUS = 'minus',
	NAVIGATION_MENU = 'navigation_menu',
	PAINT_BRUSH_EDIT_FILLED = 'paint_brush_edit_filled',
	PAINT_BRUSH_EDIT = 'paint_brush_edit',
	PAPERCLIP = 'paperclip',
	PARTY_FIVE = 'party_five',
	PARTY_FOUR = 'party_four',
	PARTY_ONE = 'party_one',
	PARTY_POPPER = 'party_popper',
	PARTY_THREE = 'party_three',
	PARTY_TWO = 'party_two',
	PEN_EDIT_FILLED = 'pen_edit_filled',
	PEN_EDIT = 'pen_edit',
	PEN_EDIT_WRITING_FILLED = 'pen_edit_writing_filled',
	PEN_EDIT_WRITING = 'pen_edit_writing',
	PIE_CHART = 'pie_chart',
	PIN = 'pin',
	PLACEHOLDER = 'placeholder',
	PLUS = 'plus',
	PRIVACY = 'privacy',
	REDDIT = 'reddit',
	REFRESH = 'refresh',
	SCROLL_Y = 'scroll_y',
	SHARE_FROM_BOX = 'share_from_box',
	SHARE_VARIANT = 'share_variant',
	SPOTIFY = 'spotify',
	STACKED = 'stacked',
	STACK_OVERFLOW = 'stack_overflow',
	STEAM = 'steam',
	SUBDIRECTORY_ARROW_LEFT = 'subdirectory_arrow_left',
	SUPPORT = 'support',
	TABLE = 'table',
	TERRORIST = 'terrorist',
	TIKTOK = 'tiktok',
	TRASH_CAN_OUTLINE = 'trash_can_outline',
	TWITCH = 'twitch',
	TWITTER = 'twitter',
	UPLOAD = 'upload',
	USER_CIRCLE_FILLED = 'user_circle_filled',
	USER_CIRCLE = 'user_circle',
	USER = 'user',
	YOUTUBE = 'youtube',

	// aliases
	/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
	APPLE_MUSIC = 'apple',
	CARET_DOWN = 'menu_down',
	CARET_LEFT = 'menu_left',
	CARET_RIGHT = 'menu_right',
	CARET_UP = 'menu_up',
	CHECKMARK = 'check',
	ENTER = 'subdirectory_arrow_left',
	ESL = 'eslgaming',
	HAMBURGER = 'menu',
	/* eslint-enable @typescript-eslint/no-duplicate-enum-values */
	/* eslint-enable @typescript-eslint/no-shadow */

	NAV_CS2_LOGO = 'nav_cs2_logo',
	NAV_LOL_LOGO = 'nav_lol_logo',
	NAV_CARET_DOWN = 'nav_caret_down',
	NAV_MINUS = 'nav_minus',
	NAV_HOME = 'nav_home',
	NAV_REFERRALS = 'nav_referrals',
	NAV_MATCHES = 'nav_matches',
	NAV_DASHBOARD = 'nav_dashboard',
	NAV_GENERAL = 'nav_general',
	NAV_CLUBS = 'nav_clubs',
	NAV_FOCUS_AREAS = 'nav_focusareas',
	NAV_AIM = 'nav_aim',
	NAV_UTILITY = 'nav_util',
	NAV_SESSIONS = 'nav_sessions',
	NAV_MAP = 'nav_map',
	NAV_PRACTICE_SERVERS = 'nav_pracservers',
}

/**
 * Use `font-size` to change the icon's size.
 *
 * NOTE: Not all icons use a square aspect ratio.
 */
@Component({
	selector: 'leetify-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
	@Input() public icon: Icon;

	protected readonly Icon = Icon;
}
