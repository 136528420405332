@if (savedStatus) {
	<div
		[ngClass]="{
			'requested': savedStatus === 'pending' || savedStatus === 'rejected',
			'following': savedStatus === 'accepted',
			'follow': savedStatus !== 'accepted' && savedStatus !== 'pending' && savedStatus !== 'rejected',
			'outlined': outline,
			'user-search': userSearch
		}"
		(mouseenter)="onHover()"
		(mouseleave)="onLeave()"
		class="follow-button"
		(click)="followAction()"
	>
		{{ text }}
	</div>
}
