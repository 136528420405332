import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { NotificationResponse } from 'leetify-shared-utils/dto';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {

	private defaultTitle = 'Leetify';
	private readonly notificationSource = new Subject<NotificationResponse>();
	public readonly notifications$ = this.notificationSource.asObservable();

	public constructor(
		protected readonly http: HttpClient,
		protected readonly titleService: Title,
	) {
		//
	}

	public reloadUserNotifications(): void {
		this.http.get<NotificationResponse>(`${environment.homeApiUrl}/api/posts/v1/notifications`, { headers: AuthService.getHeaders() }).subscribe(
			(notificationResponse: NotificationResponse) => {
				this.notificationSource.next(notificationResponse);
				return notificationResponse;
			},
			(e) => {
				console.log(e);
				return null;
			},
		);
	}

	public async markNotificationsAsRead() {
		try {
			await this.http.post(`${environment.homeApiUrl}/api/posts/v1/notifications/read`, {}, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.error(err);
		}
	}

	public setFaviconAndTitle(notificationCount: number) {
		// set favicon
		const faviconNumber = notificationCount > 9 ? 10 : notificationCount;
		const faviconPath = `assets/icons/favicons/${faviconNumber}.svg`;

		const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");

		if (link) link.href = faviconPath;

		// set title
		const newTitle = notificationCount > 9 ? `(9+) ${this.defaultTitle}` : `(${notificationCount}) ${this.defaultTitle}` ;

		// only on homepage
		if (location.pathname && location.pathname.includes('home')) {
			notificationCount === 0 ?
				this.titleService.setTitle(this.defaultTitle) :
				this.titleService.setTitle(newTitle);
		}
	}
}
